<template>
 <div class="w-full">
  <div class="w-full flex flex-col px-6 justify-center bg-white shadow-md">
      <nav-bar :email="$route.query.email"/>
    </div>
    <transition name="fade">
      <choose-login-modal v-show="modal" @closeModal="modal = false"/>
    </transition>
    <div class="w-full h-full flex flex-col items-center" :class="modal ? 'blur' : ''">
      <div class="wrapper">
        <div class="content container p-10">
            <h1 class="text-secondary text-primary text-2xl mt-6">REMOVER E-MAIL DA LISTA DE DISPAROS AUTOMÁTICOS DA TUTU DIGITAL</h1>
<hr />
            <v-input v-lowercase v-trim v-if="!$route.query.email" class="w-full mt-6" name="email" v-model="email" :error="errors.first('email')" label="Digite aqui o e-mail que deseja descadastrar" ></v-input>
            <textarea placeholder="Nos conte mais sobre sua experência, dúvidas ou reclamações sobre a Tutu Digital" v-model="mensagem" rows="4"  class="w-full mt-2 mb-4 px-6 py-3 border rounded-lg  text-gray-700 focus:outline-none"> </textarea>
            <button type="button" v-on:click="submit" :disabled="loading" class="border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-gray-200 rounded px-4 py-2">
              <span v-if="!loading">DESCADASTRAR EMAIL</span>
              <span v-else-if="loading" class="h-full flex justify-center items-center">
                <i class="fas fa-spinner fa-spin text-secondary"></i>
              </span>
             </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import NavBar from '@/components/Landing/NavBar/NavBar'
import ChooseLoginModal from '@/components/Modal/ChooseLoginModal'
import Footer from '@/components/Footer/Footer.vue'
import CarouselItems from '@/components/Carousel/Carousel.vue'
import UnsubscribeEmail from '@/services/Investor/UnsubscribeEmail/UnsubscribeEmail'
import VInput from '@/components/Inputs/Input'

export default {
  name: 'investor',
  components: {VInput, NavBar, ChooseLoginModal, Footer, CarouselItems,UnsubscribeEmail },
  data () {
    return {
      modal: false,
      loading: false,
      mensagem: '',
      email: '',
    }
  },
  methods: {
    async submit(){
      try {
        this.loading = true;
        await UnsubscribeEmail.unsubscribe({ mensagem : this.mensagem, email : this.email, investidorId : this.$route.params.uId });
         this.$store.dispatch("notification", {
          type: "success",
          message: 'Seu e-mail foi removido da nossa lista de disparos.'
        });

        const self = this;
        setTimeout(function() {
            location.href = "/"
        }, 1800);


      } catch (error) {
        this.$store.dispatch("notification", {
          type: "error",
          message: typeof(error.response.data.erros) == 'object' ? error.response.data.erros[Object.keys(error.response.data.erros)[0]]: error.response.data.erros.map(x => x[Object.keys(x)[0]])
        });
      } finally {
        this.loading = false;
      }
    }
  },
  mounted () {
    window.scrollTo(0, 0);
    this.email = this.$route.query.email;
  }
}
</script>
